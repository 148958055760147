<template>
    <div class="content_box">
        <div class="card_box" :style="{ background: `linear-gradient(180deg, ${bgColor} 0%, #FFFFFF 31%)` }">
            <div class="card_title_box">
                <div class="text_icon" :style="{ backgroundColor: myColor }"></div>
                <div class="text">验证结果</div>
            </div>
            <div class="card_text_box">
                <div class="card_icon_box">
                    <i class="el-icon-error" :style="{ color: myColor }" v-if="verifyType == 'error'"></i>
                    <i class="el-icon-success" :style="{ color: myColor }" v-if="verifyType == 'success' || verifyType == 'blackFalse'"></i>
                    <i class="el-icon-warning" :style="{ color: myColor }" v-if="verifyType == 'blackTrue'"></i>
                </div>
                <div class="card_text">
                    <div>当前</div>
                    <div style="text-decoration: underline;">{{ checkNumber }}</div>
                    <div v-if="verifyType == 'error'">
                        <span class="boldText">非官方客服</span>
                        <span>,谨防受骗</span>
                    </div>
                    <div v-if="verifyType == 'success'">
                        <span class="boldText">为官方客服</span>
                        <span>,请放心交易</span>
                    </div>
                    <div v-if="verifyType == 'blackTrue'">
                        <span class="boldText">已被拉入黑名单</span>
                        <span>,请中止交易</span>
                    </div>
                    <div v-if="verifyType == 'blackFalse'">
                        <span class="boldText">不在黑名单内</span>
                        <span>,请放心交易</span>
                    </div>
                </div>
            </div>
            <div class="card_confirm_btn" @click="closePop">
                <div class="btn" :style="{ backgroundColor: myColor }">确定</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        verifyType: {
            type: String,
            default: 'warn',
        },
        checkNumber: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            myColor: "",
            bgColor: "",
        }
    },
    mounted() {
        console.log(this.verifyType);
        if (this.verifyType == 'error') {
            // 假客服
            this.myColor = "#D81E06";
            this.bgColor = "#FFAFA5";
        } else if (this.verifyType == 'success') {
            // 真客服
            this.myColor = "#67C23A";
            this.bgColor = "#BCF3C7";
        } else if (this.verifyType == 'blackTrue') {
            // 黑号
            this.myColor = "#464646";
            this.bgColor = "#858585";
        } else if (this.verifyType == 'blackFalse') {
            // 不是黑号
            this.myColor = "#67C23A";
            this.bgColor = "#BCF3C7";
        }
    },
    methods: {
        closePop() {
            this.$emit('closeVerify');
        }
    }
}
</script>

<style lang="less" scoped>
.content_box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(3, 1, 1, 0.5);
    z-index: 999;

    .card_box {
        position: absolute;
        top: 47%;
        left: 50%;
        // width: 500px;
        min-width: 530px;
        max-width: 700px;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 20px;
        padding: 20px;
        box-sizing: border-box;


        .card_title_box {
            display: flex;
            align-content: center;
            position: relative;

            .text {
                padding-left: 15px;
                display: flex;
                align-content: center;
                font-size: 20px;
                font-weight: 700;
                color: #000;
            }

            .text_icon {
                position: absolute;
                left: 0;
                top: 0;
                width: 6px;
                height: 27px;
                border-radius: 4px 4px 4px 4px;
                margin-right: 10px;
            }
        }

        .card_text_box {
            box-sizing: border-box;
            display: flex;
            padding: 20px;
            box-sizing: border-box;
            line-height: 26px;
            font-size: 20px;

            .card_icon_box {
                font-size: 36px;
                margin-right: 5px;
            }

            .card_text {
                flex: 1;
                font-size: 20px;
                color: #464646;
                display: flex;
                align-content: center;
                flex-wrap: wrap;
                word-break: break-word;
            }

            .boldText {
                font-weight: 700;
                color: #464646;
            }
        }

        .card_confirm_btn {
            display: flex;
            justify-content: flex-end;

            .btn {
                padding: 8px 30px;
                background-color: red;
                color: #fff;
                text-align: center;
                border-radius: 20px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
</style>