
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
import { State, Getter, Action, Mutation, namespace } from 'vuex-class'
import { getUserInfoHttp } from "~/api/user";
import { distOrderCountHttp } from "~/api/order";
import { queryVerifyCustomerHttp } from "~/api/cusSer";
import { getDistributorHttp } from "~/api/distributor";
import { addBlackHttp } from "~/api/black"

@Component({
    name: "homeLogin5",
    components: {
        newImg2: require('~/components/comm/newImg/index2.vue').default,
        gameDetail: require('~/components/chat/gameDetail.vue').default,
        verifyTip: require('~/components/comm/verifyTip/index.vue').default,
    },
})
export default class homeLogin5 extends Vue {
    @Mutation private SET_TOKEN_DATA!: any;
    @State((state) => state.web.distributorsObject) distributorsObject!: any;
    @Prop(Number) divWidth_?: number



    private showVeriFy: boolean = false // 是否显示验证结果弹窗
    private verifyType: any = "" // 是否显示验证结果弹窗




    public errorToastFn: any
    public eventMsgTost: any
    public eventNotifyTost: any
    public getCookie: any
    public setCookie: any
    public qqRegular: any
    private number: any = '0'
    private isLogined: boolean = false // 是否已登录
    private actor: string = '' // 是否已登录
    private coin: string = '0.00' // 是否已登录
    private nickName: any = ''
    private newsList: any = []
    private userNavsList: any = []
    private host: any = ''
    private isQQOrWX: any = 1//1QQ 2Wx
    private checkNumber: any = ''; // 验证的客服号码
    public openUrl: any
    public customer_type: string = ''
    public showPopBox = false
    public homeConf: any = '';
    public orderNum: any = {
        completeOrderNum: 0,
        sellingOrderNum: 0,
        yesterdayOrderNum: 0,
    }
    //验证企微
    private dialogVisible: boolean = false;
    async mounted() {
        this.host = window.location.host
        if (this.getCookie('token')) {
            this.isLogined = true
            this.actor = this.getCookie('actor')
            this.nickName = this.getCookie('nickName')
        }
        if (this.distributorsObject && this.distributorsObject.adverPosMap && this.distributorsObject.adverPosMap[1]) {
            this.userNavsList = this.distributorsObject.adverPosMap[1].filter((v: any) => v.device == 0)
            console.log(this.userNavsList, '测试请');
        }
        await this.getHomeOrder()
    }
    // 安全交易数量、当前在售数量、昨日成交数量
    async getHomeOrder() {
        let { data } = await getDistributorHttp()
        if (this.errorToastFn(data)) {
            this.homeConf = data.data
            console.log(this.homeConf, 'this.homeConf')
            this.orderNum.completeOrderNum = this.homeConf.transactionNum ? this.homeConf.transactionNum : 0
            this.orderNum.sellingOrderNum = this.homeConf.goodsNum ? this.homeConf.goodsNum : 0
            this.orderNum.yesterdayOrderNum = this.homeConf.orderNum ? this.homeConf.orderNum : 0

            // let timer1 = setInterval(() => {
            //     if (this.orderNum.completeOrderNum < this.homeConf.transactionNum) {
            //         for (let i = 0; i < stepNum1; i++) {
            //             if (this.orderNum.completeOrderNum < this.homeConf.transactionNum) {
            //                 this.orderNum.completeOrderNum ++
            //             } else {
            //                 break
            //             }
            //         }
            //     } else {
            //         clearInterval(timer1)
            //     }
            // },100)
            // let timer2 = setInterval(() => {
            //     if (this.orderNum.sellingOrderNum < this.homeConf.goodsNum) {
            //         this.orderNum.sellingOrderNum ++
            //     } else {
            //         clearInterval(timer2)
            //     }
            // },1)
            // let timer3 = setInterval(() => {
            //     if (this.orderNum.yesterdayOrderNum < this.homeConf.orderNum) {
            //         this.orderNum.yesterdayOrderNum ++
            //     } else {
            //         clearInterval(timer3)
            //     }
            // },1)
        }
    }


    @Emit()
    async getUserInfo() {
        try { //登录成功后拉取用户信息
            let { data } = await getUserInfoHttp()
            if (this.errorToastFn(data)) {
                this.actor = data.data.actor
                this.coin = Number(data.data.coin).toFixed(2)
                this.nickName = data.data.nickName
                console.log(this.nickName, data.data, '昵称信息')
                this.setCookie("nickName", data.data.nickName)
                this.setCookie("userId", data.data.userId)
                this.setCookie("actor", data.data.actor)
            }
        } catch (e) {
            this.eventNotifyTost('提示', '网络超时', 'warning')
        }
    }
    get treeButtonArray() {

        if (this.distributorsObject && this.distributorsObject.adverPosMap['1'] && this.distributorsObject.adverPosMap['1'].length > 0) {
            let tampArr = this.distributorsObject.adverPosMap['1'].filter((v: any) => v.device == 0)
            console.log("进来为uwqeuwiqo", tampArr)
            return tampArr

        }
        return []
    }
    qqOrWx(v: any) {
        this.isQQOrWX = v
    }
    contantKefu(htUrl: string) {
        if (htUrl == '/allgame') {
            this.openUrl('/allgame')
        } else {
            this.customer_type = '2'
            this.showPopBox = true;
        }
    }
    async closePopBox() {
        this.showPopBox = false;
    }
    async checkSafe() {
        if (this.checkNumber === '') {
            if (this.isQQOrWX == 1) {
                this.eventMsgTost(`请输入检测的账号、手机号、微信号`);
            } else {
                this.eventMsgTost(`请输入检测的微信号、QQ号、企业微信编号`);
            }
            return false;
        }
        //验证企业微信
        var pattern = new RegExp("[\u4E00-\u9FA5]+");
        if (pattern.test(this.checkNumber)) {
            this.dialogVisible = true
            return
        }
        let result: any;
        if (this.isQQOrWX == 1) {
            result = await queryVerifyCustomerHttp({
                params: {
                    queryValue: this.checkNumber,
                }
            });
        } else {
            result = await addBlackHttp({
                params: {
                    keyWord: this.checkNumber
                }
            });
        }
        // console.log('当前验证的结果是', result);
        if (this.isQQOrWX == 1) {
            if (result.data.msg == "不存在该号码") {
                this.showVeriFy = true;
                this.verifyType = "error"
                // this.eventMsgTost(`经验证：${this.checkNumber} 为假客服。`, 'warning');

            } else {
                this.showVeriFy = true;
                this.verifyType = "success"
                // this.eventMsgTost(`经验证：${this.checkNumber} 为真客服。`, 'success');
            }
        } else {
            if (result.data.msg == '成功') {
                this.showVeriFy = true;
                this.verifyType = "blackFalse"
                // this.$alert('该用户未被拉入黑名单，如后续有违规请联系客服，避免线下交易。', '未违规', {
                //     confirmButtonText: '确定',
                // });
            } else {
                this.showVeriFy = true;
                this.verifyType = "blackTrue"
                // this.$alert('该用户已被拉入黑名单，请终止交易。', '存在违规', {
                //     confirmButtonText: '确定',
                // });
            }
        }
    }
    closeVerify() {
        this.showVeriFy = false;
    }

}
